body {
  margin: 0;
  font-family: 'montserrat, sans-serif', serif;
  background-color: #FFF !important;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*body*/
body, html {
  /*mobile*/
  font-size: 12px;
  /*todo media query pour le desktop etc */
}
body, * {
  /*color : #000000 !important;*/
  font-family: 'Montserrat', serif;
}
body {
  background-color: #FFF;
}
main {
  padding: 1rem 0;
}
.font-weight-bolder {
  font-weight: 900 !important;
}
/*headings*/
h1, h2, h3, h4, h6 {
  color: #000 !important;
}
h1, h2, h3 {
  font-weight: 900 !important;
  letter-spacing: -1px;
}
h1 {
  font-size: 2.8em;
  margin: 1em 0;
}
h2 {
  /*1.33 from h1 ratio*/
  font-size: 2.6em;
  margin: 0.8em 0;
}
h3 {
  font-size: 1.7em !important;
  /*margin: 0.5em 0 !important;*/
  margin: 0.5em 0;
}
h5 {
  font-size: 0.9em;
}
/* buttons */
.btn-dark {
  border-color: #000 !important;
  background-color: #000 !important;
  color: #fff !important;
}
.card {
  box-shadow: 7px 5px 5px 1px rgb(0 0 0 / 8%) !important;
  border-radius: 0;
}

/* large screen */

@media (min-width: 992px) {
  h1 {
    font-size: 3em;
    margin-top: 0.4em;
  }
}

/* Desktop device */

@media (min-width: 1200px) {
  h1 {
    font-size: 4em;
    margin-top: 0.4em;
  }
  h2 {
    font-size: 4em;
    margin-top: 1.3em;
  }
  h3 {
    font-size: 2.8em !important;
  }
  h5 {
    font-size: 1.3em;
  }
  .full-width-marge {
    max-width: 85% !important;
  }
}
