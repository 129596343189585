#login-komeo-animation {
    display: none;
}

#app-login-container {
    height: 100%;
}

#alert-bad-login {
    position: fixed;
    top: 0;
}

.alert-danger h5 {
    color : #ea5455;
}

p.welcome-message {
    font-size: 1.5em;
}

.card-text {
    font-size: 2.2em;
    margin-bottom: 1em;
    line-height: 1em;
}

#login-komeo-logo {
    width: 50%;
}

.auth-login-form {
    width: 90%;
}

.login-input {
    width: 55%;
}

.form-label {
    font-size: 1.1em;
}

.mb-3 {
    margin-bottom: 1rem !important;
    width: 65%;
}

#login-box {
    border: 2px solid #000;
    border-radius: 20px;
    padding: 1em;
    margin-top: 1em;
}

.auth-login-form {
    width: 100%;
}

@media (min-width: 768px) {
    #login-box {
       width:60%;
    }
}

@media (min-width: 992px) {
    #app-login-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    #form-container-col {
        position: relative !important;
    }
    .auth-login-form {
        width: 100%;
    }
    .card-text {
        margin-top: 0.5em;
    }
    #login-box {
        position: absolute;
        top: 2%;
        background-color: #fff;
        width: 44%;
        right: 13%;
    }
    #login-komeo-animation {
        display: block;
    }
}

@media (min-width: 1055px) {
    #login-box {
        width: 42%;
        right: 14%;
    }
}
@media (min-width: 1098px) {
    #login-box {
        width: 40%;
        right: 16%;
    }
}

@media (min-width: 1260px) {
    #login-box {
        width: 38%;
        right: 19%;
    }
}

@media (min-width: 1381px) {
    #login-box {
        width: 35%;
        right: 21%;
    }
}

@media (min-width: 1450px) {
    #form-container-col {
        width: 90%;
    }
}

@media (min-width: 1510px) {
    #form-container-col {
        width: 82%;
    }
}

@media (min-width: 1660px) {
    #form-container-col {
        width: 75%;
    }
}


@media (min-width: 1750px) {
    #form-container-col {
        width: 72%;
    }
}
@media (min-width: 1870px) {
    #form-container-col {
        width: 67%;
    }
}

@media (min-width: 2030px) {
    #form-container-col {
        width: 62%;
    }
}
@media (min-width: 2190px) {
    #form-container-col {
        width: 58%;
    }
}

@media (min-width: 2345px) {
    #form-container-col {
        width: 53%;
    }
}

@media (min-width: 2550px) {
    #form-container-col {
        width: 49%;
    }
}

@media (min-width: 2760px) {
    #form-container-col {
        width: 45%;
    }
}

@media (min-width: 2760px) {
    #form-container-col {
        width: 42%;
    }
}
@media (min-width: 3200px) {
    #form-container-col {
        width: 39%;
    }
}
