/* contentTypes */
.mobile-contentType-name {
    margin-left: 1.1em;
}
.contentType-naming {
    margin-left: 0.7em;
}
.contentType-heading {
    font-size: 1.25em;
}
.contentType-description {
    font-size: 1.09em;
}
.contentType-cost {
    font-size: 1.28em;
    color: #000 !important;
}
/* media queries */
@media (min-width: 768px) {
    .points .card, .info .card {
        padding: 3em 0;
    }
    .contentType-description {
        display: block;
    }
    .mobile-contentType-name {
        display: none;
    }
    .contentType-item {
        max-width: 34%;
        width: 60%;
    }
    .contentType-item:nth-child(2n) {
        margin-left: 1.1em;
    }
    .contentType-item:nth-child(1n) {
        margin-right: 1.1em;
    }
    .contentType-cost {
        align-self: end;
        margin-bottom: 0.8em;
    }
}

@media (min-width: 768px) {
    .contentType-item {
        max-width: 100%;
        width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

@media (min-width: 1200px) {
    h3 {
        margin-bottom: 0.635em !important;
    }
    .contentType-item {
        max-width: 45%;
        width: 45%;
    }
    .contentType-heading {
        font-size: 1.6em;
        margin:0 !important;
    }
    .contentType-description {
        font-size: 1.3em;
    }
    .contentType-cost {
        font-size: 1.4em;
    }
    .contentType-item:nth-last-child(1) .contentType-cost {
        margin-bottom:1.3em;
    }
    .contentType-item .align-self-center {
        align-self: end !important;
    }
    .tc-card-body {
        padding-bottom: 0 !important;
    }
}
