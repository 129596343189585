.messageContainers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}

.messageBox {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.message-head {
  text-align: center;
  font-size: 20px;
}

.message {
  color: #000;
  font-size: 17px;
  text-align: justify;
  padding: 0 15px;
}

.button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
}
.mh-10 {
  max-height: 10%;
}
