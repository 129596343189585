/* referent card mobile */
/* typos */
.referent-card h4 {
    font-size: 1.7em;
}
.referent-card h5 {
    font-size: 1.4em;
}
.referent-card {
    flex: 1;
}
.referent-card-body {
    flex-direction: column;
}
.referent-info-container {
    justify-content: space-around;
}
.referent-info-container h4{
    text-align: center;
}
.referent-card .card-body {
    padding: 2em 1.5em;
}
.referent-image-wrapper {
    flex: 50%;
}
#referent-card-img {
    width: 150px;
    height: 150px;
}
/* todo : bootstrap override */
.badge-referent-container .bg-dark {
    background-color: #000 !important;
    color: #fff !important;
}
.referent-image-wrapper #referent-profile-image {
    margin-bottom: 0;
}
@media (min-width: 576px) {
    .referent-card-body {
        flex-direction: row;
    }
    .referent-info-container {
        flex: 50%;
        text-align: left;
    }
    .referent-info-container h4 {
        text-align: left;
    }
}
/*Desktop*/
@media (min-width: 1200px) {
    .referent-info-container {
        justify-content: start;
        padding-top: 2em;
        margin-bottom: 1em;
    }
    .referent-info-container h4{
        text-align: left;
        font-size: 2.3em;
    }
    #referent-card-img {
        width: 200px;
        height: 200px;
    }
    #referent-badge {
        margin-top: 0;
        margin-bottom: 2.5em;
        padding: 6px;
    }
}
