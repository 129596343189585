.menu-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    color: #000;
    backface-visibility: hidden;
    border: 0.3rem solid #000;
    border-radius: 3rem;
    transition: transform 0.2s cubic-bezier(0.235, 0, 0.05, 0.95);
}

.menu-button:hover {
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0) !important;
}

.client-button {
    margin-bottom: 3px;
}

#dashboard-client .menu-button {
    padding: 0.1rem 0.6rem;
    font-family: 'Montserrat', serif;
}
