/*points*/
/*chart component*/
.additional-balance-data-row {
    margin-bottom: 1.5em;
}

.card-text{
    font-size: 1.7em;
}

@media (min-width: 992px) {
    .card-text{
        font-size: 1.2em;
    }
}
/* Desktop device */

@media (min-width: 1200px) {
    .card-text {
        font-size: 1.3em;
    }
        h4 {
        font-size: 1.4em;
    }
        h3 {
        font-size: 1.1em;
    }
}

@media (min-width: 1600px) {
    .card-text {
        font-size: 1.5em;
    }
}
