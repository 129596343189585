#loader-container {
    display: none;
}
#loader-img {
    max-width: 50%;
    max-height: 50%;
}
#loader-container p {
    font-size: 3em;
}
