#header-logo {
    max-width: 26vw;
}

#retour-client-beta {
    border-width: thin;
    font-weight: 500;
    padding-left: 1.1em;
}

#retour-client-label {
    margin-left: 0.3em;
}

#retour-client {
    margin: 0.2em 0.4em;
    max-width: 20%;
}

#version-naming {
    margin-left: 2px;
}

#profile {
    border: black 2px solid;
    border-radius: 15px;
}

#profile-navigation {
    font-size: 1.2rem;
}
#profile-navigation {
    padding: 0.5em;
}
#profile-header>div {
    padding: 0.5em;
}

@media (min-width: 768px) {
    #retour-client-label {
        font-size: 0.8em;
    }
    #profile-header {
        font-size: 1.3rem;
        border-bottom: rgba(20,20,20,0.1) solid 1px;
    }
    #profile-navigation {
        font-size: 1.4rem;
    }
}
