/*toolbox*/
.disabled {
    pointer-events: none;
}
#toolbox-card-snippet .card-header {
    padding-bottom: 0;
}
#toolbox-card-snippet .card .card-header h3 {
    margin: 0 0 0.5em 0;
}
#toolbox-card-snippet .card-subtitle {
    margin-top: 0;
}
@media (max-width: 576px) {
    .width-toolbox-item {
        width: 100%;
    }
}
@media (min-width: 576px) {
    .toolbox-buttons-container {
        /*width: 29%;*/
        margin: 1rem;
    }
    .toolbox-buttons-container:nth-child(3n) {
        margin-left: 0
    }
    .toolbox-item-inner {
        justify-content: start;
    }
    .toolbox-item-name {
        display: block;
        font-size: 0.98em;
        font-weight: 700;
    }
    .btn-outline-dark {
        width: 100% !important;
        border-width: 2px !important;
    }
    .btn-outline-dark img {
        margin: 0 0.5em;
    }
    .btn.btn-icon.toolbox-btn {
        padding: 0 0.736rem 0 0;
    }
    .width-toolbox-item {
        width: 55%;
    }
}

@media (min-width: 992px) {
    .width-toolbox-item {
        width: 70%;
    }
}

@media (min-width: 1200px) {
    .width-toolbox-item {
        width: 90%;
    }
    .toolbox-btn {
        padding: 0.2em !important;
    }
    #toolbox-card-snippet h3{
        margin-bottom: 0.3em !important;
    }
    #toolbox-card-snippet h5{
        margin-bottom: 1em;
    }

}
@media (min-width: 1370px) {
    .width-toolbox-item {
        width: 70%;
    }
}
