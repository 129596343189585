h2 {
    font-size: 2em;
}
.copy-icon {
    cursor: pointer;
}
.copy-value {
    margin-right: 0.6em;
    background-color: rgba(222, 222, 222, 0.56);
    border-radius: 5px;
    padding: 0.5em;
    max-width: 70%;
    overflow: hidden;
}
.pass-item {
    background-color: rgba(238, 238, 238, 0.56);
    border-radius: 5px;
    padding: 1em;
    margin: 1em;
}
