#new-password-row{
    margin-top: .5rem;
}
#change-password-section-submit {
    margin-top: 2rem;
}
.change-password-section {
    padding-left: 1rem;
}
.ml-2 {
    margin-left: 2.6rem !important;
}