ul {
    padding-left: 0;
    margin-bottom: 0;
}

#refresh-button {
    font-family: unset;
}

.solid-divider {
    border-top: 3px solid #000;
    width: 20%;
    margin: 1.7rem;
}
