/*Contents*/
.rdt_TableBody .rdt_TableRow:nth-child(1n) {
    /*background-color: #f4f4f4 !important;*/
}
.rdt_TableBody .rdt_TableRow:nth-child(2n) {
    background-color: #ececec !important;
}
/*Status*/
#contents .badge {
    color: #121212 !important;
}
/*"Programmé"*/
.bg-secondary {
    background-color: #878787 !important;
    border-color: #878787 !important;
    box-shadow: 0 0 10px #878787 !important;
}
/*"À valider"*/
.bg-danger {
    background-color: #dc458d !important;
    border-color: #dc458d !important;
    box-shadow: 0 0 10px #dc458d !important;
}
/*"Réalisé"*/
.bg-success {
    background-color: #6e61e4 !important;
    border-color: #6e61e4 !important;
    box-shadow: 0 0 10px #6e61e4 !important;
}
/*"Publié"*/
.bg-info {
    background-color: #26be68 !important;
    border-color: #26be68 !important;
    box-shadow: 0 0 10px #26be68     !important;
}
/*"Annulé"*/
.bg-dark {
    /*background-color: #ffffff !important;*/
}
