#loader-container {
    display: none;
}

h1 {
    margin-bottom: 0 !important;
}
#referent-sentence {
    margin: 1.3em;
    font-size: 1.3em;
}

#header-sticky {
    position: sticky;
    top:0;
    z-index: 1;
    background-color: #fff;
    padding-bottom: 1.2em;
}
