/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
//(TC02)
@media ( min-width : 1200px ) {
  .tc-card-body {
    display: flex;
    flex-wrap: wrap;
  }
  .tc-transaction-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 50%;
    padding: 1px 5px;
  }
}
//(TB02)
.btn.btn-icon.toolbox-btn {
  //padding: 0 0.736rem 0 0;
  //padding: 0;
}
//.toolbox-demo-inline-spacing {
//  width: 50%;
//  margin: auto;
//}
//CR01
.referent-card-img{
 width: 150px !important;
 height: 150px !important;
}
//body
body {
  color : #000000 !important;
  font-family: 'montserrat, sans-serif', serif;
  font-weight: normal;
}
//headings
h1, h2 {
  font-weight: 900;
  letter-spacing: -1px;
}
h1 {
  font-size: 3.5em;
  margin: 1em 0;
}
h2 {
  //1.33 from h1 ratio
  font-size: 2.6em;
  margin: 0.8em 0;
}
